import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"
import { useSpring, useTrail, animated, config } from "react-spring"
import ShellacLead from "../images/homepage_shellac_lead.jpg"
import EyelashLead from "../images/homepage_eyelash_lead.jpg"
import LashLiftLead from "../images/homepage_lashlift_lead.jpg"
import TreatmentsLead from "../images/homepage_treatments_lead.jpg"

import Icon from "../utilities/icons"
import BeautyIconManicure from "../images/icons/001-manicure.svg"
import BeautyIconWax from "../images/icons/002-wax.svg"
import BeautyIconMassage from "../images/icons/003-massage.svg"
import BeautyIconTreatment from "../images/icons/004-beauty-treatment.svg"
import BeautyIconRelax from "../images/icons/009-relax.svg"
import BeautyIconTreatmentTwo from "../images/icons/010-beauty-treatment-1.svg"
import BeautyIconWaxTwo from "../images/icons/011-wax-1.svg"
import BeautyIconMassageTwo from "../images/icons/006-massage-1.svg"
import BeautyIconMassageThree from "../images/icons/007-massage-2.svg"

const BeautyIcons = [
  BeautyIconManicure,
  BeautyIconWax,
  BeautyIconMassage,
  BeautyIconTreatment,
  BeautyIconRelax,
  BeautyIconTreatmentTwo,
  BeautyIconWaxTwo,
  BeautyIconMassageTwo,
  BeautyIconMassageThree,
]

const AngledLayer = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  transform: skewY(${props => (props.upslope ? "5deg" : "-5deg")});
  position: ${props => props.position};
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  transform-origin: 0;
  background: #d53f8c; /* fallback for old browsers */
  background: #ffdce0;
  /*
  background: linear-gradient(
    to bottom,
    #ef017c,
    #d60063
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media (max-width: 900px) {
    height: auto;
    padding: 40px 0 100px 0;
  }

  @media (max-width: 600px) {
    height: auto;
    padding: 40px 0 20px 0;
  }
`

const Section = styled.section`
  position: relative;
  padding: 80px 0;

  @media (max-width: 900px) {
    padding: 40px 0;
  }

  .side-paras {
    max-width: 60%;

    @media (max-width: 900px) {
      max-width: 100%;
    }
  }
  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
  .beautician-side {
    width: calc(40% - 20px);
    display: block;
    height: auto;
    align-self: center;

    @media (max-width: 900px) {
      width: 100%;
      margin-top: 40px;
    }
  }
`

const BeautyIconBubble = styled.div`
  width: calc(33.33% - 10px);
  padding-bottom: calc(33.33% - 10px);
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffe9ed;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: auto;

  @media (max-width: 900px) {
    width: calc(11.11% - 10px);
    padding-bottom: calc(11.11% - 10px);
  }
  @media (max-width: 700px) {
    width: calc(20% - 10px);
    padding-bottom: calc(20% - 10px);
    margin-right: 10px;
    &:nth-of-type(5),
    &:nth-of-type(9) {
      margin-right: 0;
    }
  }
`

const ProfileCard = styled.section`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .profile-card-img {
    width: calc(100%);
    overflow: hidden;
    position: relative;
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-wrap: nowrap;
    }

    @media (max-width: 700px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    /*  &:after {
      content: "";
      background: #ffe6ff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 60px;
      border-radius: 10px;
      z-index: -1;
    } */
  }

  .profile-card-details {
    width: calc(50% - 10px);
  }

  .profile-card-details-item {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .profile-card-details-item-label {
    color: #ef017c;
    font-size: 13px;
    line-height: 10px;
    display: block;
    font-weight: 700;
  }

  .profile-card-details-item-desc {
    display: block;
  }
`

const CardLinks = styled.section`
  position: relative;
  background: #f1f5f8;
  padding-top: 40px;

  p {
    max-width: 60%;
  }
`

const LinkCardContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;

  .inner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .CardLink {
    width: calc(33.33% - 30px);
    transition: all 0.2s ease-out;
    cursor: pointer;
    margin-bottom: 20px;

    .upper {
      transform-origin: 0;
      background: rgba(255, 255, 255, 1);
      border-radius: 5px;
      padding: 20px 20px 40px 20px;
      position: relative;
      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-top: 2px solid #ef017c;

      h4 {
        /*transform: rotate(-5deg) skew(-5deg);*/
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        /*transform: rotate(-5deg) skew(-5deg);*/
        max-width: 100%;
        width: 100%;
        line-height: 20px;
      }
    }
    .lower {
      width: 100%;
      /*transform: rotate(-5deg) skew(-5deg);*/
      margin-top: -50px;
      margin-left: 10px;

      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      transition: all 0.15s ease-out;

      img {
        width: 100%;
        height: 150px;
        display: block;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .cardLink-arrow {
      background: #fff;
      margin-top: -25px;
      border-radius: 5px;
      height: 50px;
      position: relative;
      z-index: -1;
      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

      .right-arrow-icon {
        position: absolute;
        bottom: -5px;
        right: -5px;
        /*transform: rotate(-5deg) skew(-5deg);*/
        transition: all 0.2s ease-out;
      }
    }

    &:hover {
      /*margin-top: -5px;*/
      transform: translateY(-5px) !important;

      .upper {
        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2);
      }

      .cardLink-arrow {
        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2);
      }

      /*.right-arrow-icon {
      bottom: 5px;
      right: 5px;
    }*/
    }

    @media (max-width: 900px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 600px) {
      width: calc(100%);
      margin-bottom: 40px;
    }
  }
`

const IndexPage = ({ location }) => {
  //console.log(location.pathname)

  const [on, toggle] = useState(false)
  const animation = useSpring({
    //transform: on ? "translateY(0px)" : "translateY(100%)",
    bottomOffset: "0",
  })

  const trail = useTrail(3, {
    opacity: on ? 1 : 0,
    transform: on ? "translateY(0)" : "translateY(400px)",
  })

  var card_links = {
    0: {
      title: "Treatments",
      text:
        "I offer a wide range of treatments at very reasonable prices. Find out what I offer, and a detailed description of each treatment.",
      url: "/treatments",
      img_src: TreatmentsLead,
      img_alt: "Woman having make up done",
    },
    1: {
      title: "Lash Lift",
      text:
        "Make your lashes look fuller and longer, with less maintenance than extensions.",
      url: "/treatments/lash-lift",
      img_src: LashLiftLead,
      img_alt: "Lash Lift",
    },
    /*
    2: {
      title: "Eyelash Extensions",
      text:
        "A fantastic semi-permanent method of creating longer, fuller glamorous lashes that will last for up to 2 months.",
      url: "/treatments/eyelash-extensions",
      img_src: EyelashLead,
      img_alt: "Eyelash Extensions",
    },*/
    2: {
      title: "Shellac",
      text:
        "Shellac is a polish that goes on like nail polish but wears like a gel.",
      url: "/treatments/shellac",
      img_src: ShellacLead,
      img_alt: "Shellac Nails",
    },
  }

  return (
    <Layout location={location.pathname}>
      <SEO
        title="Beauty Gems | Mobile Beautician Newark"
        description="Mobile beauty therapist, offering a wide range of treatments at great prices in Newark, Claypole, Fernwood, Winthorpe, Coddington, Collingham, Elston, Muskham and other surrounding areas."
        keywords={[`gatsby`, `application`, `react`]}
      />

      <Section>
        <div className="inner-container">
          <div className="side-paras">
            <p>
              Welcome to the Beauty Gems website, my name is Gemma and I'm a
              fully qualified beautician. I am a mobile beautician and I offer a
              wide range of treatments at competitive prices.
            </p>
            <p>
              I bring all the treatments you need, to you. The next time you
              need that manicure, pedicure, bikini wax, eyebrow tweeze, or
              facial, you needn't go all the way to the beauty salon, get in
              touch and I'll come to you!
            </p>
            <p>
              As a local mobile beautician I am flexible when you are booking an
              appointment and would be more than happy to complete your
              treatments during weekdays, evenings or at weekends, whichever
              suits you best.
            </p>{" "}
            <div style={{ marginTop: "0px" }} className="global-btn-wrapper ">
              <Link className="global-btn global-btn_blue" to="/contact">
                Book Appointment
              </Link>
            </div>
          </div>
          <div className="beautician-side">
            <ProfileCard>
              <div className="profile-card-img">
                {BeautyIcons.map(icon => {
                  return (
                    <BeautyIconBubble
                      style={{ backgroundImage: `url(${icon})` }}
                    />
                  )
                })}
              </div>
            </ProfileCard>
          </div>
        </div>
      </Section>
      <Waypoint
        topOffset="-100px"
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <CardLinks>
        {/*<AngledLayer style={{ background: "#F1F5F8" }} upslope grey>*/}
        <LinkCardContainer>
          <div className="inner-container">
            {trail.map((props, i) => (
              <animated.div style={props} className="CardLink">
                <Link to={card_links[i]["url"]}>
                  <div className="upper">
                    <h4>{card_links[i]["title"]}</h4>
                    <p>{card_links[i]["text"]}</p>
                  </div>
                  <div className="lower">
                    <img
                      src={card_links[i]["img_src"]}
                      alt={card_links[i]["img_alt"]}
                    />
                  </div>
                  <div className="cardLink-arrow">
                    <Icon
                      name="right_arrow"
                      color="#ef017c"
                      width="24"
                      height="24"
                    />
                  </div>
                </Link>
              </animated.div>
            ))}
          </div>
        </LinkCardContainer>
        {/*</AngledLayer>*/}
      </CardLinks>
    </Layout>
  )
}

export default IndexPage
